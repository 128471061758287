/** Montserrat Family **/
@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

/** Futura New Family **/
@font-face {
	font-family: 'Futura New';
	src: url('./assets/fonts/FuturaNew/FuturaNewBook-Reg.ttf');
	font-weight: normal;
	font-style: normal;
}
