// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import './fonts.css';

@import '~vis-timeline/dist/vis-timeline-graph2d.min.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

:root {
  color-scheme: dark;
}

/* base classes */

.white-text {
  color: #fff !important;
}

.black-text {
  color: #000 !important;
}

.title-text {
  color: #6d3af7 !important;
}

.primary-text {
  color: var(--main-color) !important;
}

.secondary-text { 
  color: var(--secondary-font-color) !important;
}

.title-size {
  font-size: var(5.3vw) !important;
}

.primary-size {
  font-size: var(4.2vw) !important;
  letter-spacing: var(-0.06px) !important;
}

.secondary-size { 
  font-size: var(3.7vw) !important;
  letter-spacing: var(-0.07px) !important;
}

.center-controls {
  z-index: 100;
  position: absolute;
  display: flex;

  top: calc(50% - 25px);
  left: calc(50% - 90px);

  ion-icon {
    width: 26px;
    height: 26px;
    margin: 13px;

    &.forward {
      margin-left: 26px;
    }

    &.replay {
      margin-right: 26px;
    }
  }

  .vg-icon-pause {
    font-size: 54px !important;
  }

  .vg-icon-play_arrow {
    font-size: 54px !important;
  }
}

.header-ios ion-toolbar:last-child {
  --border-width: 0;
}

vg-mute.volume_off > .icon:before {
  content: '\e032';
}

vg-mute.volume_up > .icon:before {
  content: '\e033';
}

.loading-wrapper.sc-ion-loading-md {
  -webkit-box-shadow: none;
  box-shadow: none;
  --background: transparent;
}

.vg-playback-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
  cursor: pointer;
  color: white;
  line-height: 50px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.vg-playback-button .button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}

ion-toolbar {
  --min-height: 60px;
  border: 0px;
  box-shadow: 0 5px 15px 0 rgba(80, 119, 173, 0.2);

  ion-title {
    color: var(--main-color);
    font-size: var(--title-font-size);
    font-weight: var(--title-font-weigth);
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }

  ion-icon {
    width: 18px;
    height: 18px;
    --fill: #a647f6;
    fill: #a647f6;
    color: #a647f6;
  }

  ion-back-button {
    --padding-top: 12px;
    --padding-end: 12px;
    --padding-bottom: 12px;
    --padding-start: 12px;
    --border-radius: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 48px;
    height: 48px;
  }

  ion-back-button::part(icon) {
    width: 18px;
    height: 18px;
    --fill: #a647f6;
    fill: #a647f6;
  }

  ion-button {
    --padding-top: 12px;
    --padding-end: 12px;
    --padding-bottom: 12px;
    --padding-start: 12px;
    --border-radius: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 48px;
    height: 48px;
  }
}

ion-header {
  --background: #eff1f4;

  &:after {
    left: 0 !important;
    bottom: 0 !important;
    position: absolute !important;
    width: 100% !important;
    height: 0px !important;
    background: none !important;
    content: '';
  }

  ion-buttons {
    margin: 0;
    margin-inline-start: 0;
  }
}

ion-content {
  --background: #eff1f4;
}

ion-input {
  --padding-start: 4.1vw !important;
  --padding-end: 4.1vw !important;

  text-align: left;
  color: var(--main-color);
  font-weight: 500;

  --font-size: 4.2vw;
  font-size: 4.2vw !important;
  letter-spacing: -0.069px;
  line-height: 4.2vw;

  box-sizing: border-box;
  height: 12.5vw;
  width: auto;
  border: 1px solid #d4d8e2;
  border-radius: 2.66vw;
}

ion-item {
  &.text-field {
    --border-color: #d4d8e2;
    --background: transparent;
    --background-activated: transparent;
    --transition: background-color 200ms linear;
    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0px 0px 1px 0px;
    --highlight-color-focused: #d4d8e2;

    margin-top: 4vw;
    margin-bottom: 4vw;

    ion-input {
      --padding-top: 2.66vw !important;
      --padding-bottom: 2.66vw !important;
      --padding-start: 0 !important;
      --padding-end: 0 !important;
      width: 100%;
      border: none;
      border-radius: 0;
      text-align: center;
    }
  }
}

ion-textarea {
  --padding-start: 4.1vw !important;
  --padding-end: 4.1vw !important;

  text-align: left;
  color: var(--main-color);
  font-weight: 500;

  --font-size: 4.2vw;
  font-size: 4.2vw !important;
  letter-spacing: -0.069px;
  line-height: 4.2vw;

  box-sizing: border-box;
  width: auto;
  border: 1px solid #d4d8e2;
  border-radius: 2.66vw;

  textarea {
    height: 100%;
  }
}

.bottom-button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.button-wrapper {
  padding: 5.5vw;
  width: 100%;

  ion-button {
    &.main,
    &.cancel,
    &.disabled {
      --background: #f0f2f5;
      --background-focused: #f0f2f5;
      --background-hover: #f0f2f5;
      --background-activated: #f0f2f5;

      --box-shadow: none;
      --background: #f0f2f5;
      --background-focused: #f0f2f5;
      --background-hover: #f0f2f5;
      --border-radius: 6.4vw;

      border: 0px solid #d5dbe4;
      box-shadow: none;
      height: 12.8vw;
      border-radius: 6.4vw;
      box-shadow: -1px -1px 1px 0 #ffffff, -7px -7px 14px 0 rgba(255, 255, 255, 0.8), 7px 7px 14px 0 #d3dbe6;

      --color: #6d3af7;
      --color-activated: #6d3af7;
      --color-focused: #6d3af7;
      --color-hover: #6d3af7;

      color: #6d3af7;
      font-size: 4.8vw;
      font-weight: 500;
      letter-spacing: -0.077vw;
      line-height: 4.8vw;
      text-align: center;
      text-transform: none;
    }

    &.cancel {
      color: var(--error-color);
      --color: var(--error-color);
      --color-activated: var(--error-color);
      --color-focused: var(--error-color);
      --color-hover: var(--error-color);
    }

    &.disabled {
      color: #727988;
      --color: #727988;
      --color-activated: #727988;
      --color-focused: #727988;
      --color-hover: #727988;
    }
  }
}

.service-filter-segment {
  margin: 10vw 0 8vw 0;
  padding: 0vw 2.65vw 0 2.65vw;

  ion-segment-button {
    margin: 0 2.65vw 0 2.65vw;
    height: 6.4vw;
    min-height: fit-content;
    border-radius: 8vw;
    flex-grow: 0;
    font-size: 3.2vw;
    line-height: 3.6vw;
    font-weight: 400;
    text-transform: none;
    --background-checked: #6d3af7;
    --background: #d9d9d9;
    --color: #727988;
    --border-style: none;
    --indicator-height: 0%;
  }
}

/* Block of promotional offers */

.offers-segment {
  margin: 2vw 0;
  background: none;

  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }

  &::after {
    content: ' ';
    white-space: pre;
    width: 2vw;
  }

  ion-segment-button {
    width: 28vw;
    height: 28vw;
    border-radius: 6vw;
    min-width: 28vw;
    border-color: none;
    margin-bottom: 2vw;
    --border-style: none;
    --indicator-height: 0%;

    &:first-of-type {
      margin-left: 2vw;
    }

    &:last-of-type {
      margin-right: 2vw;
    }

    ion-img {
      object-fit: cover;
      display: block;
      max-width: 26vw;
      width: 26vw;
      height: 26vw;
      border-radius: 7.4vw !important;
      overflow: hidden;
      box-shadow: -1px -1px 1px 0 #ffffff;
    }
  }
}

.custom-spinner {
  .spinner-bubbles.sc-ion-loading-md {
    --spinner-color: var(--main-color);
  }
}

/* ALERT POPUPS */

.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
  border-radius: 8vw !important;
}

.alert-wrapper {
  --max-width: 85%;

  .alert-head {
    border-bottom: 1px solid #d4d8e2;
    padding: 8vw 5.3vw 4vw 5.3vw;

    .alert-title {
      color: var(--main-color);
      font-size: 5.3vw;
      font-weight: 600;
      letter-spacing: -0.09vw;
      line-height: 5.3vw;
    }

    .alert-sub-title {
      color: #6d3af7;
      font-size: 5.3vw;
      font-weight: 600;
      letter-spacing: -0.09vw;
      line-height: 5.3vw;
    }
  }

  .alert-message {
    padding: 5.3vw !important;
  }

  .alert-button-group {
    padding: 4vw 5.3vw 1.3vw 5.3vw;

    .alert-button {
      display: block;
      border-radius: 6.66vw;
      background-color: #f0f2f5;
      box-shadow: -1px -1px 1px 0 #ffffff, -7px -7px 14px 0 rgba(255, 255, 255, 0.8), 7px 7px 14px 0 #d3dbe6;

      color: #6d3af7;
      font-size: 4.8vw;
      font-weight: 500;
      letter-spacing: -0.08px;
      line-height: 4.8vw;
      text-align: center;
      margin: 0;
      margin-bottom: 4vw;
      text-transform: none;
      width: 78.4vw;

      .alert-button-inner {
        justify-content: center;
      }
    }

    .back {
      background: transparent;
      box-shadow: none;
      color: #949494;
      text-decoration: underline;
      font-size: 3.7vw;
    }
  }
}

.tariff-plan-change-alert {
  .alert-wrapper .alert-head {
    border-bottom: none;
  }

  .alert-wrapper .alert-head .alert-title {
    text-align: center;
  }

  .alert-wrapper .alert-message {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #2d384a;
    height: fit-content;
    max-height: fit-content;
  }

  .data-p {
    font-size: 5.3vw;
    font-weight: 600;
    border-bottom: 1.3px solid;
  }

  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5.3vw;
    padding-left: 0;
    justify-content: space-between;
    margin-top: 5.3vw;
    border-radius: 6.4vw;
    margin-bottom: 5.3vw;
    position: relative;
    box-shadow: -1px -1px 1px #fff, -7px -7px 14px #fffc, 7px 7px 14px #d3dbe6;
    overflow: hidden;
  }

  .big-icon {
    background-color: #5530ea;
    width: 18vw;
    height: 12vw;
    border-radius: 0 4.5vw 4.5vw 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    p {
      color: white;
      text-align: center;
      font-size: 6vw;
      font-weight: 500;
      line-height: 6vw;
      letter-spacing: 0.26px;
    }
  }

  .title {
    color: var(--main-color);
    font-style: normal;
    font-weight: 500;
    font-size: 5.3vw;
    letter-spacing: -0.155vw;
    line-height: 7.47vw;
    text-transform: none;
    // margin-bottom: 1vw;
  }

  .abon_mes {
    color: var(--secondary-font-color);
    font-weight: normal;
    font-size: 3.7vw;
    letter-spacing: -0.0587vw;
    line-height: 3.7vw;
    width: fit-content;
    text-transform: none;
  }

  .tariff-info {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 0;
    flex-basis: 80%;
    margin-left: 1vw;
  }

  .bg1 {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 60vw;
    border-radius: 0 6.4vw 6.4vw 0;
    background: url('/assets/img/main/bg1.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg2 {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 35vw;
    border-radius: 0 6.4vw 6.4vw 0;
    background: url('/assets/img/main/bg2.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  p {
    margin-left: 5.3vw;
    margin-right: 5.3vw;
  }

  .close-cross-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 4.8vw;
    width: 6.78vw;
    height: 6.78vw;
    color: #727988;
  }
}

.mvno-additional-service-alert {
  .alert-wrapper {
    width: 89vw;
  }

  .alert-message {
    height: fit-content;
    max-height: fit-content;
    padding: 1.8vw 8vw 0 !important;
  }

  .alert-head {
    border-bottom: none;
    padding: 8.5vw 8vw 0 !important;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3.2vw;
  }

  .status-off {
    color: #0fbe66;
    display: flex;
    align-items: center;
    font-size: 2.6vw;
  }

  .title {
    margin-bottom: 1.6vw;
    margin-top: 0;
    color: var(--main-color);
    font-weight: 500;
    font-size: 4.2vw;
    letter-spacing: -0.059vw;
    line-height: 4.3vw;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;
    flex-basis: 85%;
  }

  .on {
    flex-basis: 60%;
  }

  p {
    margin: 0;
    font-size: 3.2vw;
    color: var(--main-color);
    margin-bottom: 0.8vw;
  }

  .icon-off {
    color: #6d3af7;
    width: 6.6vw;
    height: 6.6vw;
  }

  span {
    margin-right: 2.04vw;
  }
}

.alert-city {
  .alert-wrapper {
    [aria-checked='true'].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
      color: #6d3af7;
    }

    .alert-radio-group.sc-ion-alert-md {
      border: 0;
    }

    .alert-radio-group.sc-ion-alert-ios {
      border: 0;
    }

    .alert-radio-label.sc-ion-alert-ios {
      color: var(--main-color);
    }

    .alert-message {
      padding: 1.3vw !important;
    }

    .alert-radio-inner.sc-ion-alert-md {
      background-color: #6d3af7;
    }

    .alert-radio-icon.sc-ion-alert-md {
      border-color: #6d3af7;
    }
  }
}

.alert-thank {
  .alert-wrapper {
    /* width: 89vw; */
  }

  .alert-head {
    border-bottom: none;
    padding-top: 10.6vw;
    padding-bottom: 5.6vw;
    background-color: #6AB63C;

    .alert-title {
      color: white;
      font-size: 5.3vw;
      font-weight: 600;
      text-align: center;
      letter-spacing: -0.085vw;
      line-height: 5.3vw;
    }

    .alert-sub-title {
      color: white;
      font-size: 3.7vw;
      font-weight: 400;
      letter-spacing: -0.059vw;
      line-height: 5.3vw;
      margin-top: 9.3vw;
      margin-left: 20vw;
      margin-right: 20vw;
      max-width: 48vw;
      text-align: center;

    }
  }

  .alert-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

    ion-icon {
      width: 46.6vw;
      height: 13.9vw;
      margin-bottom: 4.5vw;
    }

    .alert-link {
      cursor: pointer;
    }

    > div {
      width: 100%;
      /* align-items: center; */
      display: flex;

      &:first-child {
        background: #6AB63C;
      }

      > div {
        margin: 10px auto;
      }
    }
  }
}

.alert-app-rating {
  .alert-wrapper {
    /* width: 89vw; */
    //height: 59.5vh
  }

  .alert-head {
    border-bottom: none;
    padding: 9vw 9vw 9vw 9vw;

    .alert-title {
      color: var(--main-color);
      font-size: 5.3vw;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.085vw;
      line-height: 5.3vw;
    }
  }

  .alert-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

    min-height: 7.7vw;

    ion-icon {
      width: 8.3vw;
      height: 7.8vw;
      margin-left: 2vw;
      margin-right: 2vw;
    }
  }

  .alert-input-group {
    padding-bottom: 0;
    padding-top: 9.3vw;

  }

  .alert-input-wrapper {
    textarea {
      border-bottom: none;
      background: #FFFFFF;
      border: 1px solid #D4D8E2;
      border-radius: 2vw;
      padding: 3.2vw;
      height: 15.8vh;
      margin: 0;
    }
  }

  .alert-button-group {
    padding-top: 9.3vw;
  }

  .alert-button {
    margin-bottom: 9.3vw;
  }

}

.with-price {
  .alert-title {
    margin-right: 22vw;
  }

  .alert-sub-title {
    margin: 0;
    position: absolute;
    top: 8vw;
    right: 5.3vw;
    text-align: right;
  }
}

.paid-warning {
  .alert-message {
    font-size: 3.2vw;
    color: #475060;
    max-height: 90% !important;

    strong {
      font-size: 4.2vw;
    }

    div {
      &.compare {
        background: rgba(85, 48, 234, 0.1);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
        border-radius: 5.3vw;
        margin: 5vw 0 0 0;
        color: #475060;
        font-size: 2.8vw;
        display: table;
        width: 100%;
        padding: 2vw;

        b {
          font-size: 2.8vw;
        }

        .row {
          display: table-row;

          div {
            padding: 2.5vw;
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            &:first-child {
              width: 60%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.no-title {
  .alert-head {
    display: none;
  }

  .alert-message {
    padding-top: 8vw !important;
  }
}

.without-message {
  .alert-message {
    display: none;
  }
}

.offer-form {
  .alert-wrapper .alert-head {
    border-bottom: none;
  }

  .alert-wrapper .alert-head .alert-sub-title {
    font-weight: 500;
    text-align: center;
    margin-top: 5.3vw;
  }

  .alert-title {
    text-align: center;
  }

  .alert-input-wrapper {
    text-align: left;
    color: var(--main-color);
    font-weight: 500;

    --font-size: 4.2vw;
    font-size: 4.2vw !important;
    letter-spacing: -0.069px;
    line-height: 4.2vw;

    box-sizing: border-box;
    width: auto;
    border: 1px solid #d4d8e2;
    border-radius: 2.66vw;

    margin: 2.66vw 0;
    height: 12.5vw;

    input {
      line-height: 4.5vw;
      text-align: center;
      border-bottom: none;
      padding: 0;
      margin: 0;
      margin-top: 3.5vw;
    }
  }
}

.another-title {
  .alert-wrapper .alert-head .alert-title {
    font-size: 4vw;
  }
}

.move-message {
  .alert-message {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: center;
    font-size: 3.2vw;
    line-height: 5.3vw;

    .offer {
      color: #6d3af7;
    }

    .alert-title {
      line-height: 6.9vw;
    }
  }
}

.news-alert {
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(180%);

  .alert-head {
    display: none;
  }

  .alert-wrapper .alert-message {
    padding: 0 !important;
    max-height: 200vw;
    max-width: 89vw;
    height: fit-content;
    overflow: hidden;
  }

  .alert-wrapper .alert-button-group .alert-button {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.mvno-privacy-policy-alert {
  .alert-wrapper .alert-message {
    font-size: 3.7vw;
    color: #2d384a;
    padding: 0 5.3vw !important;
    text-align: center;

    p {
      margin: 0;
      font-weight: 600;
    }
    .btm {
      margin-bottom: 3.2vw;
    }

    .top {
      margin-top: 3.2vw;
    }
  }

  .alert-wrapper .alert-head .alert-title {
    font-size: 5.3vw;
    font-weight: 600;
    letter-spacing: -0.09vw;
    line-height: 6.9vw;
  }

  .alert-wrapper .alert-head .alert-sub-title {
    color: #2d384a;
    font-size: 5.3vw;
    font-weight: 600;
    letter-spacing: -0.09vw;
    line-height: 6.9vw;
    margin-top: 0;
  }

  .alert-wrapper .alert-head {
    padding-bottom: 5.3vw;
    padding-left: 5.3vw;
    padding-right: 5.3vw;
  }

  .alert-wrapper .alert-button-group {
    padding: 7.4vw 7.4vw 1.9vw;
  }
  .alert-wrapper .alert-button-group .alert-button {
    margin: 0;
    margin-bottom: 5.3vw;
  }
}

.mvno-selected-service-alert {
  .alert-wrapper .alert-head .alert-title {
    text-align: center;
  }
  .alert-wrapper .alert-message {
    padding: 0 !important;
    max-height: 200vw;
    max-width: 89.3vw;
    height: fit-content;
    overflow-y: scroll;
  }

  .alert-wrapper .alert-button-group .alert-button {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &.without-head {
    .alert-head {
      display: none;
    }
  }
}

.mvno-selected-service-result-alert {
  &.w-message {
    .alert-wrapper .alert-head {
      padding-bottom: 8vw;
    }

    .alert-wrapper .alert-message {
      display: none;
    }
  }

  .alert-wrapper .alert-message {
    max-height: 200vw;
    max-width: 89.3vw;
    height: fit-content;
    overflow: hidden;
    text-align: center;
    color: var(--main-color);
    line-height: 4.552vw;
    letter-spacing: -0.0773vw;
    padding: 6.36vw 3.47vw !important;
  }

  .alert-wrapper .alert-head {
    border-bottom: none;
    background-color: #6ab63c;
    text-align: center;
  }

  &.error {
    .alert-wrapper .alert-head {
      border-bottom: none;
      background-color: #7b818b;
      text-align: center;
    }
  }

  .alert-wrapper .alert-title {
    color: white;
    letter-spacing: -0.073vw;
    line-height: 6.93vw;
  }

  .alert-wrapper .alert-button-group {
    display: none;
  }
}

/* VIS */
.vis-timeline {
  border: none !important;
  border-radius: 0 0 2.66vw 2.66vw;
  height: 8vw;
}

.vis-timeline .vis-custom-time.video-time {
  /* height: 19px !important; */
  background-color: rgb(236, 85, 123);
}

.vis-text {
  font-size: 3.5vw;
}

.timelinecontainer {
  cursor: default;
}

.vis-timeline .vis-item.range {
  border-radius: 0%;
}

.vis-timeline.root {
  border-width: 0 !important;
}

.vis-timeline .vis-item {
  border-width: 0;
  background-color: #d3dbe6;
  padding: 0;
}

.vis-timeline .itemset .background {
  height: 19px;
}

.vis-timeline .vis-item.detection {
  background-color: #6d3af7;
  height: 19px;
  z-index: 1000;
}
.vis-timeline .vis-item.detection .selected {
  background-color: #6d3af7;
}

.vis-timeline .vis-item.selected {
  background-color: #d3dbe6;
  padding: 0;
}

.vis-timeline .time-axis .grid.major {
  border: 0;
}

.vis-timeline .time-axis .text {
  font-size: 12.6px;
}

.vis-timeline {
  border-radius: 0 0 1.33vw 1.33vw;
}

.axis,
.axis text {
  pointer-events: none;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vis-item {
  border: none !important;
}

.vis-item.vis-selected {
  border: none !important;
}

.vis-item.vis-range {
  border-radius: 0 !important;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: #dadada;
  height: 100% !important;
}

.autopay-picker {
  --ion-color-primary: var(--main-color);
  --height: 86vw !important;

  .picker-opt.picker-opt-selected {
    color: var(--main-color);
    font-size: 4.2vw;
    font-weight: 500;
    letter-spacing: -0.1vw;
    line-height: 4.2vw;
    text-align: center;
  }

  .picker-opts {
    font-size: 3.7vw;
    letter-spacing: -0.07vw;
    line-height: 3.7vw;
    top: 18vw;
  }

  .picker-opt {
    color: var(--main-color);
    font-size: 3.7vw;
    letter-spacing: -0.07vw;
    line-height: 3.7vw;
    text-align: center;
    height: 11vw;
  }

  .picker-wrapper {
    background: #f0f2f5 !important;
    --background: #f0f2f5;
  }

  .picker-toolbar.sc-ion-picker-md {
    justify-content: center;
  }

  .picker-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4vw 5.3vw;
    height: 36.6vw !important;

    .picker-toolbar-cancel {
      position: absolute;
      bottom: 0;
    }

    .picker-button {
      display: block;
      border-radius: 6.66vw;
      background-color: #f0f2f5;
      box-shadow: -1px -1px 1px 0 #ffffff, -7px -7px 14px 0 rgba(255, 255, 255, 0.8), 7px 7px 14px 0 #d3dbe6;

      color: #6d3af7;
      font-size: 4.8vw;
      font-weight: 500;
      letter-spacing: -0.08px;
      height: 12vw !important;
      line-height: 12vw;
      text-align: center;
      margin-bottom: 4vw;
      text-transform: none;
      width: 78.4vw;
    }
  }
}

.swiper-pagination {
  top: 2.6vw;
  height: 2.6vw;

  .swiper-pagination-bullet {
    width: 10vw;
    height: 1.2vw;
    display: inline-block;
    border-radius: 0.6vw;
    /* background: #ffffff; */
    /* opacity: 0.4; */
  }

  .swiper-pagination-bullet-active {
    /* opacity: 0.9; */
    /* background: #ffffff; */
  }
}

ion-slides {
  &.ios {
    .swiper-pagination {
      top: 7vw !important;
    }
  }
}

.context-menu {
  --width: fit-content;
}

.unlock-doors {
  /*--box-shadow: -1px -1px 1px 0 #FFFFFF, -7px -7px 14px 0 rgba(255,255,255,0.8), 7px 7px 14px 0 #D3DBE6;*/

  .popover-content {
    width: 80vw;
    border-radius: 8vw !important;
  }
}

/* Intercom status */
.intercom-status {
  position: absolute;
  top: 5px;
  right: 5px;

  ion-icon {
    &.connected {
      --fill: green;
      fill: green;
      --color: green;
      color: green;
      display: block;
    }

    &.part {
      --fill: #f3da0b;
      fill: #f3da0b;
      --color: #f3da0b;
      color: #f3da0b;
      display: block;
    }

    &.disconnected {
      --fill: red;
      fill: red;
      --color: red;
      color: red;
      display: block;
    }

    display: none;
    width: 10px;
    height: 10px;
  }
}

.news-card-alert {
  margin: 0;
  display: flex;
  flex-direction: column;
  --background: none;
  height: 100%;
  width: 89vw;
  overflow-y: hidden;

  .close-cross-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: 4.8vw;
    width: 6.78vw;
    height: 6.78vw;
  }

  .header-with-t-img {
    padding-bottom: 2.4vw;
  }

  .header-without-t-img {
    padding: 8vw 5.3vw 0vw 5.3vw;
  }

  .title-img {
    overflow: hidden;
    height: fit-content;
    max-height: 60vh;
    object-fit: cover;
  }

  ion-card-title {
    color: var(--main-color);
    font-size: 4.8vw;
    font-weight: 600;
    letter-spacing: -0.058vw;
    line-height: 4.8vw;
    text-align: start;
  }

  ion-card-content {
    div {
      margin-top: 2.4vw;
    }

    p {
      font-size: 3.2vw;
      font-weight: 500;
      letter-spacing: -0.051vw;
      line-height: 3.2vw;
      text-align: start;
    }

    font-size: 3.2vw;
    font-weight: 500;
    letter-spacing: -0.051vw;
    line-height: 3.2vw;
    text-align: start;
    height: fit-content;

    &.content-without-t-img {
      padding: 0vw 5.3vw 4vw 5.3vw;
    }
  }
}

.service-card-alert {
  margin: 0;
  display: flex;
  flex-direction: column;
  --background: none;
  height: 100%;
  overflow-y: hidden;

  .header-with-t-img {
    padding: 5vw 6.4vw 6.4vw;
  }

  .title-img {
    overflow: hidden;
    height: fit-content;
  }

  .price {
    font-size: 4.8vw;
    line-height: 4.8vw;
    font-weight: 500;
    color: #6d3af7;
    margin-top: 3vw;
  }

  ion-card-title {
    color: var(--main-color);
    font-size: 5.3vw;
    font-weight: 600;
    letter-spacing: -0.058vw;
    line-height: 5.3vw;
    text-align: start;
  }

  ion-card-content {
    padding: 0 6.4vw 6.4vw;
    div {
      margin-top: 2.4vw;
      // padding: 5vw 6.4vw 6.4vw;
    }

    p {
      font-size: 3.2vw;
      font-weight: 500;
      letter-spacing: -0.051vw;
      line-height: 3.2vw;
      text-align: start;
    }

    font-size: 3.7vw;
    font-weight: 500;
    letter-spacing: -0.051vw;
    line-height: 4vw;
    text-align: start;
    height: fit-content;
    color: var(--secondary-font-color);
  }
}

ion-grid {
  &.top {
    border-radius: 0 0 8vw 8vw;
    background-color: #f0f2f5;
    box-shadow: 0 1.33vw 4vw 0 rgba(80, 119, 173, 0.2);
    padding: 4vw 5.3vw 2.66vw 5.3vw;
  }

  ion-col {
    color: var(--secondary-font-color);
    font-size: 3.6vw;
    letter-spacing: -0.04vw;
    line-height: 3.6vw;

    &.amount {
      color: #6d3af7;
      font-size: 7.5vw;
      font-weight: 500;
      letter-spacing: -0.12px;
      line-height: 9.6vw;
    }

    &.date {
      color: var(--main-color);
      font-size: 7.5vw;
      font-weight: 500;
      letter-spacing: -0.12px;
      line-height: 9.6vw;
    }
  }
}

@media (orientation: landscape) and (min-width: 420px) {
  ion-input {
    --padding-start: 2.05vw !important;
    --padding-end: 2.05vw !important;

    --font-size: 2.1vw;
    font-size: 2.1vw !important;
    letter-spacing: -0.03vw;
    line-height: 2.1vw;

    height: 6.25vw;
    border-radius: 1.33vw;
  }

  ion-item {
    &.text-field {
      margin-top: 2vw;
      margin-bottom: 2vw;

      ion-input {
        --padding-top: 1.33vw !important;
        --padding-bottom: 1.33vw !important;
      }
    }
  }

  ion-textarea {
    --padding-start: 2.05vw !important;
    --padding-end: 2.05vw !important;

    --font-size: 2.1vw;
    font-size: 2.1vw !important;
    letter-spacing: -0.03vw;
    line-height: 2.1vw;

    border-radius: 1.33vw;
  }

  .offers-segment {
    margin: 1vw 0vw;

    &::after {
      content: ' ';
      white-space: pre;
      width: 1vw;
    }

    ion-segment-button {
      width: 14vw;
      height: 14vw;
      border-radius: 3vw;

      &:first-of-type {
        margin-right: 2.65vw;
      }

      &:last-of-type {
        margin-right: 1.65vw;
      }

      ion-img {
        display: block;
        max-width: 13vw;
        width: 13vw;
        height: 13vw;
        border-radius: 3.7vw !important;
      }
    }
  }

  .button-wrapper {
    padding: 2.75vw;
    width: 100%;

    ion-button {
      &.main,
      &.cancel,
      &.disabled {
        --border-radius: 3.2vw;
        border-radius: 4vw;
        height: 6.4vw;
        border-radius: 3.2vw;
        font-size: 2.4vw;
        letter-spacing: -0.036vw;
        line-height: 2.4vw;
      }
    }
  }

  .alert-wrapper {
    --max-width: 80%;
    border-radius: 4vw !important;

    .alert-head {
      padding: 4vw 2.65vw 2vw 2.65vw;

      .alert-title {
        font-size: 2.65vw;
        letter-spacing: -0.045vw;
        line-height: 2.65vw;
      }

      .alert-sub-title {
        font-size: 2.65vw;
        letter-spacing: -0.045vw;
        line-height: 2.65vw;
      }
    }

    .alert-message {
      padding: 2.65vw !important;
    }

    .alert-button-group {
      padding: 2vw 2.65vw 0.65vw 2.65vw;

      .alert-button {
        border-radius: 3.33vw;

        font-size: 2.4vw;
        letter-spacing: -0.04px;
        line-height: 2.4vw;
        margin-bottom: 2vw;
        width: 83.7vw;
      }
    }
  }

  .news-alert {
    .alert-wrapper .alert-message {
      max-height: 35vw;
      margin: 0 !important;
    }

    .alert-wrapper {
      --max-width: 44.5vw;
      max-width: 44.5vw;
    }
  }

  .tariff-plan-change-alert {
    .alert-wrapper {
      width: 44.5vw;
    }
    .alert-wrapper .alert-message {
      max-height: 40vw;
    }

    .data-p {
      font-size: 2.65vw;
    }

    .flex-container {
      padding-left: 1.3vw;
      margin-top: 2.65vw;
      border-radius: 3.2vw;
      margin-bottom: 2.65vw;
    }

    .big-icon {
      width: 11.2vw;
      height: 6.65vw;
      border-radius: 2.25vw;
      p {
        font-size: 3.3vw;
        line-height: 3.3vw;
        letter-spacing: 0.13px;
      }
    }

    .title {
      font-size: 2.65vw;
      letter-spacing: -0.0775vw;
      line-height: 3.735vw;
      margin-bottom: 0.5vw;
    }

    .abon_mes {
      font-size: 1.85vw;
      letter-spacing: -0.02935vw;
      line-height: 1.85vw;
    }

    .bg1 {
      width: 30vw;
      border-radius: 0 3.2vw 3.2vw 0;
    }

    .bg2 {
      width: 17.5vw;
      border-radius: 0 3.2vw 3.2vw 0;
    }

    p {
      margin-left: 2.65vw;
      margin-right: 2.65vw;
    }

    .close-cross-icon {
      margin: 2.4vw;
      width: 3.39vw;
      height: 3.39vw;
    }
  }

  .mvno-selected-service-result-alert {
    .alert-wrapper {
      width: 44.5vw;
    }
    &.w-message {
      .alert-wrapper .alert-head {
        padding-bottom: 4vw;
      }
    }

    .alert-wrapper .alert-message {
      max-height: 100vw;
      max-width: 44.65vw;
      line-height: 2.276vw;
      letter-spacing: -0.03865vw;
      padding: 3.18vw 1.735vw !important;
    }

    .alert-wrapper .alert-title {
      letter-spacing: -0.0365vw;
      line-height: 3.465vw;
    }
  }

  .mvno-privacy-policy-alert {
    .alert-wrapper {
      width: 44.5vw;
    }
  
    .alert-wrapper .alert-message {
      font-size: 1.85vw;
      padding: 0 2.65vw !important;
      overflow-y: scroll;

      .btm {
        margin-bottom: 1.6vw;
      }

      .top {
        margin-top: 1.6vw;
      }
    }

    .alert-wrapper .alert-head .alert-title {
      font-size: 2.65vw;
      letter-spacing: -0.045vw;
      line-height: 3.45vw;
    }

    .alert-wrapper .alert-head .alert-sub-title {
      font-size: 2.65vw;
      letter-spacing: -0.045vw;
      line-height: 3.45vw;
      margin-top: 0 !important;
    }

    .alert-wrapper .alert-head {
      padding: 2vw 2.65vw;
    }

    .alert-wrapper .alert-button-group {
      padding: 1vw 3.87vw 1vw;
    }
    .alert-wrapper .alert-button-group .alert-button {
      margin-bottom: 2vw;
    }
  }

  .mvno-additional-service-alert {
    .alert-wrapper {
      width: 44.5vw;
    }

    .alert-message {
      padding: 0.9vw 4vw 0 !important;
    }

    .alert-head {
      padding: 4.25vw 4vw 0 !important;
    }

    .item {
      margin-top: 1.6vw;
    }

    .status-off {
      font-size: 1.3vw;
    }

    .title {
      margin-bottom: 0.8vw;
      font-size: 1.85vw;
      letter-spacing: -0.0295vw;
      line-height: 2.15vw;
    }

    .text-block {
      margin-bottom: 1.5vw;
      flex-basis: 80%;
    }

    .on {
      flex-basis: 60%;
    }

    p {
      font-size: 1.35vw;
      margin-bottom: 0.4vw;
    }

    .icon-off {
      width: 3.3vw;
      height: 3.3vw;
    }

    span {
      margin-right: 1.02vw;
    }
  }

  .mvno-selected-service-alert {
    .alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
      border-radius: 4vw!important;
      width: fit-content;
      max-width: 80vw;
    }
    .alert-wrapper .alert-message {
      max-height: 100vw;
      // max-width: 44.65vw;
      overflow-y: scroll;
    }

    .alert-wrapper .alert-button-group .alert-button {
      width: 100%;
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }

    .service-card-alert {
      flex-direction: row;
      width: fit-content;

      .header-with-t-img {
        padding-bottom: 2vw;
      }
    }

    .info-block {
      flex-basis: 60%;
    }

    .title-img {
      flex-basis: 40%;
      border-radius: 4vw 0 4vw 0;
      height: 100%;
      max-height: 100%;
    }
  }

  .with-price {
    .alert-title {
      margin-right: 11vw;
    }

    .alert-sub-title {
      top: 4vw;
      right: 2.65vw;
    }
  }

  .paid-warning {
    .alert-message {
      font-size: 1.6vw;

      strong {
        font-size: 2.1vw;
      }

      div {
        &.compare {
          border-radius: 2.75vw;
          margin: 2.5vw 0 0 0;
          font-size: 1.4vw;
          padding: 1vw;

          b {
            font-size: 1.4vw;
          }

          .row {
            div {
              padding: 1.75vw;
            }
          }
        }
      }
    }
  }

  .no-title {
    .alert-message {
      padding-top: 4vw !important;
    }
  }

  .offer-form {
    .alert-wrapper .alert-head .alert-sub-title {
      margin-top: 2.65vw;
    }

    .alert-wrapper {
      overflow-y: auto;
    }

    .alert-input-wrapper {
      --font-size: 2.1vw;
      font-size: 2.1vw !important;
      line-height: 2.1vw;

      border-radius: 1.33vw;

      margin: 1.33vw 0;
      height: 6.25vw;

      input {
        line-height: 2.25vw;
        margin-top: 1.75vw;
      }
    }
  }

  .another-title {
    .alert-wrapper .alert-head .alert-title {
      font-size: 2vw;
    }
  }

  .move-message {
    .alert-message {
      font-size: 1.6vw;
      line-height: 2.65vw;
    }
  }

  .bottom-button {
    margin-top: 3.65vw;
  }

  .autopay-picker {
    --ion-color-primary: var(--main-color);

    .picker-opt.picker-opt-selected {
      color: var(--main-color);
      font-size: 3.2vw;
      letter-spacing: -0.1vw;
      line-height: 3.2vw;
    }

    .picker-opts {
      color: var(--main-color);
      font-size: 1.85vw;
      letter-spacing: -0.035vw;
      line-height: 1.85vw;
      top: 9vw;
    }

    .picker-opt {
      opacity: 0.5;
      font-size: 1.85vw;
      letter-spacing: -0.035vw;
      line-height: 1.85vw;
      height: 5.5vw;
    }

    .picker-wrapper {
      padding: 2vw;
      width: 47vw !important;
      height: 45.5vw !important;
      border-radius: 1.33vw !important;
    }

    .picker-toolbar {
      padding: 2vw 3.65vw;
      height: 18.3vw !important;

      .picker-button {
        display: block;
        border-radius: 3.33vw;

        font-size: 2.4vw;
        letter-spacing: -0.04px;
        height: 6vw !important;
        line-height: 6vw;
        margin-bottom: 2vw;
        width: 39.2vw;
      }
    }
  }

  .unlock-doors {
    .popover-content {
      width: 50vw;
      border-radius: 4vw !important;
    }
  }
}

ion-menu {
  ion-title {
    margin-right: 20px;
  }
}

ion-tabs {
  --background: #f0f2f5;
  --background-focused: #f0f2f5;
  --background-hover: #f0f2f5;

  ion-tab-bar {
    height: 20vw;
    --min-height: 20vw;
    --padding-top: 4vw;
    --padding-start: 4vw;
    --padding-bottom: 4vw;
    --padding-end: 4vw;

    --background: #f0f2f5;
    --background-focused: #f0f2f5;
    --background-hover: #f0f2f5;

    background-color: #f0f2f5;
    box-shadow: 0 -5px 15px 0 rgba(80, 119, 173, 0.1);
    border: none;

    ion-tab-button {
      div {
        width: 12vw;
        height: 12vw;

        --background: #f0f2f5;
        --background-focused: #f0f2f5;
        --background-hover: #f0f2f5;

        --border-radius: 50%;
        border-radius: 50%;
        box-shadow: -1px -1px 1px 0 #ffffff, -7px -7px 14px 0 rgba(255, 255, 255, 0.8), 7px 7px 14px 0 #d3dbe6;

        background-size: contain;
        --color: var(--main-color);

        ion-icon {
          width: 6.66vw;
          height: 6.66vw;

          margin: 2.5vw;

          --fill: #727988;
          fill: #727988;
        }

        &.active {
          box-sizing: border-box;
          border: 1px solid #f0f2f5;
          box-shadow: inset -1px -1px 1px 0 #ffffff, inset -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
            inset 2px 2px 4px 0 #d3dbe6, -1px -1px 1px 0 #ffffff, -2px -2px 4px 0 rgba(255, 255, 255, 0.8),
            2px 2px 4px 0 #d3dbe6;

          ion-icon {
            --fill: #a647f6;
            fill: #a647f6;
          }
        }
      }
    }
  }
}

.action-sheet-button-inner.sc-ion-action-sheet-md {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

// because ios use white color for text on light modal window
.action-sheet-button.sc-ion-action-sheet-ios {
  --button-color: var(--main-color);
  color: var(--main-color);
}

// so that the window does not cover the entire screen.
.sc-ion-action-sheet-md-h {
  --max-height: 50%;
  --height: 50%;
}
// so that the window does not cover the entire screen.
.sc-ion-action-sheet-ios-h {
  --max-height: 60%;
  --height: 60%;
}

.playlists-item {
  .sc-ion-buttons-md-s ion-button {
    --padding-start: 0;
    --padding-end: 0;
  }
}

// so that the links do not have a background color
a {
  color: var(--main-color);
}

.calendar-modal {
  background: transparent;
  --background-opacity: 0.2;
  --background: transparent;

  ion-backdrop::part(backdrop) {
    opacity: 0.9;
    background: black;
  }

  app-calendar-date-array-selection {
    height: fit-content;
  }
}

@media (orientation: landscape) and (min-width: 420px) {
  .title-size {
    font-size: var(2.65vw) !important;
  }
  
  .primary-size {
    font-size: var(2.1vw) !important;
    letter-spacing: var(-0.06px) !important;
  }
  
  .secondary-size { 
    font-size: var(1.85vw) !important;
  }
  
  ion-tabs {
    ion-tab-bar {
      height: 10vw;
      --min-height: 10vw;
      --padding-top: 2vw;
      --padding-start: 2vw;
      --padding-bottom: 2vw;
      --padding-end: 2vw;

      ion-tab-button {
        div {
          width: 6vw;
          height: 6vw;

          ion-icon {
            width: 3.33vw;
            height: 3.33vw;

            margin: 1.25vw;
          }
        }
      }
    }
  }

  .news-alert {
    .alert-wrapper .alert-button-group .alert-button {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .news-card-alert {
    display: flex;
    flex-direction: column;
    width: fit-content;
    --background-color: var(--ion-color-primary);

    .close-cross-icon {
      margin: 2.4vw;
      width: 3.39vw;
      height: 3.39vw;
    }

    .header-without-t-img {
      padding: 4vw 2.6vw 0vw 2.6vw;
    }

    .header-with-t-img {
      padding-bottom: 1.2vw;
    }

    .title-img {
      max-height: 30vh;
      width: 44.7vw;
    }

    ion-card-title {
      color: var(--main-color);
      font-size: 2.4vw;
      font-weight: 600;
      letter-spacing: -0.029vw;
      line-height: 2.4vw;
    }

    ion-card-content {
      font-size: 1.6vw;
      letter-spacing: -0.0255vw;
      line-height: 1.6vw;

      &.content-without-t-img {
        padding: 0vw 2.6vw 2vw 2.6vw;
      }

      div {
        margin-top: 1.2vw;
      }

      p {
        font-size: 1.6vw;
        letter-spacing: -0.0255vw;
        line-height: 1.6vw;
      }
    }
  }

  .service-card-alert {
    height: 100%;
    width: 44.65vw;

    .header-with-t-img {
      padding: 2.5vw 3.2vw 3.2vw;
    }

    .price {
      font-size: 2.4vw;
      line-height: 2.4vw;
      margin-top: 1.35vw;
    }

    ion-card-title {
      font-size: 2.4vw;
      letter-spacing: -0.029vw;
      line-height: 2.4vw;
    }

    ion-card-content {
      padding: 0 3.2vw 3.2vw;
      div {
        margin-top: 1.2vw;
      }

      p {
        font-size: 1.6vw;
        letter-spacing: -0.0255vw;
        line-height: 1.6vw;
      }

      font-size: 1.6vw;
      letter-spacing: -0.0255vw;
      line-height: 2.5vw;
    }
  }

  ion-grid {
    &.top {
      border-radius: 0 0 4vw 4vw;
      box-shadow: 0 0.615vw 2vw 0 rgba(80, 119, 173, 0.2);
      padding: 2vw 2.65vw 1.33vw 2.65vw;
    }

    ion-col {
      font-size: 1.85vw;
      letter-spacing: -0.025vw;
      line-height: 1.85vw;

      &.amount {
        font-size: 3.75vw;
        letter-spacing: -0.05vw;
        line-height: 4.8vw;
      }

      &.date {
        font-size: 3.75vw;
        letter-spacing: -0.05vw;
        line-height: 4.8vw;
      }
    }
  }

  .service-filter-segment {
    margin: 5vw 0 4vw 0;
    padding: 0vw 1.325vw 0 1.325vw;

    ion-segment-button {
      margin: 0 1.325vw 0 1.325vw;
      height: 3.2vw;
      border-radius: 4vw;
      font-size: 1.6vw;
      line-height: 1.8vw;
    }
  }
}
