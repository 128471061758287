// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #F0F2F5;
  --ion-color-primary-rgb: 240, 242, 245;
  --ion-color-primary-contrast: #FFFFFF;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5530EA;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #6D3AF7;
  --ion-color-secondary-rgb: 109, 58, 247;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** light **/
  --ion-color-light: #FFFFFF;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #FFFFFF;
  --ion-color-light-tint: #FFFFFF;

  /** font family of the app **/
  --ion-font-family: "Montserrat", serif;
  --ion-background-color: #EFF1F4;

  --ion-tab-bar-background: #FFFFFF;
  --ion-tab-bar-font: var(--ion-font-family);
  --ion-tab-bar-font-size: 10px;
  --ion-tab-bar-color: #B5BBC9;
  --ion-tab-bar-color-selected: #167FEF;
  --ion-tab-bar-icon-color: var(--ion-tab-bar-color);
  --ion-tab-bar-icon-color-selected: var(--ion-tab-bar-color-selected);

  --button-bg: linear-gradient(90deg, #0ED0FA 0%, #1780F0 100%);
  --button-color: #FFFFFF;

  --contrast-button-bg: #FFFFFF;
  --contrast-button-color: #727988;
  --contrast-button-border: 1.5px solid #B5BBC9;

  --button-font: var(--ion-font-family);
  --button-font-size: 14px;
  --button-font-weigth: bold;

  --contrast-button-font: var(--ion-font-family);
  --contrast-button-font-size: 14px;
  --contrast-button-font-weigth: normal;

  /** application page title size **/
  --title-font-size: 18px;

  /** the weight of the title font of the application pages **/
  --title-font-weigth: 600;

  --main-font-size: 4.2vw;
  --main-font-weigth: 500;
  --main-font-spacing: -0.06vw;

  --second-font-size: 12px;
  --second-font-weigth: 500;
  --main-font-spacing: -0.07px;

  /** the main text color in the app **/
  --main-color:	#2D384A;
  --main-color-rgb: 45, 56, 74;

  /** the font color of the app's secondary text **/
  --secondary-font-color: #727988;
  --secondary-font-color-rgb: 114,121,136;
  --contrast-second-color: #E4E4E4;

  --error-color: #EC557B;
  --error-color-rgb: 236, 85, 123;

  --icon-selected-color: #B5BBC9;
  --icon-color: #167FEF;

  --fotter-bottom-color: #DCDCDC;
}


ion-tab-bar {
  --min-height: 60px;
}

ion-menu {
  --width: 100%;
}

ion-button {
  position: -webkit-sticky !important;
}
